import "./Home.css";
import React, { useState } from "react";
import get from "../../Utiles/get";
import config from "../../Constants/enviroment";
import MyBox from "../../Components/MyBox/MyBox";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import Header from "../../Section/Header/Header";
import Footer from "../../Section/Footer/Footer";
import usePost from "../../CustomHooks/usePost";
import TrackableLink from "../../Components/TrackableLink/TrackableLink";

const Home = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState("dev");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  get(
    `${config.buildStatusEndPoint}?env=${selectedEnvironment}`,
    setLoading,
    setData
  );

  console.log("data is : ", data);

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const environmentChanged = async (e) => {
    setLoading(true); // I know this is wrong, the responsibility of setting the loading variable should be the responsility of either get() or home page
    get(
      `${config.buildStatusEndPoint}?env=${selectedEnvironment}`,
      setLoading,
      setData
    );
    setSelectedEnvironment(e.target.value);
  };
  return (
    <>
      <div className="container-fluid hero1">
        <Header />
        <div className="text-home">
          <h1>Street Things Deployer.</h1>
          <p className="text">
            The Deployer is an on-demand application designed to automatically
            provision and manage the necessary Azure infrastructure for
            StreetThings. It handles the creation of resources such as AKS
            (Azure Kubernetes Services), databases, event buses, and ACRs (Azure
            Container Registries), and deploys the StreetThings microservices
            without requiring any manual intervention. The Deployer streamlines
            the process, ensuring efficient and fully automated deployments.
          </p>
          <p className="textt">
            The back-end services for StreetThings, its infrastructure code, and
            the StreetThings Deployer are all open-source projects available in
            this Azure DevOps organization.
          </p>
          <p className="textt-swap">
            The Deployer job is to deploy StreetThings, a process that takes
            approximately 10 to 15 minutes to provision resources and deploy the
            StreetThings microservice. The infrastructure provisioned includes
            AKS (Azure Kubernetes Services), databases, event buses, ACRs (Azure
            Container Registries), and other necessary resources. Once deployed,
            StreetThings will remain available for one hour. After that, the
            Deployer will initiate the destruction sequence, which will
            decommission the entire infrastructure.
          </p>
          <div className="list-select">
            <p>
              StreetThings can be deployed in two environments (Dev and Test)
            </p>

            <form onSubmit={handleSubmit}>
              <select value={selectedEnvironment} onChange={environmentChanged}>
                <option value="dev">Dev</option>
                <option value="test">Test</option>
              </select>
            </form>
          </div>
        </div>

        <div className="boxxx">
          {loading ? (
            <div className="spinner">
              <span>🔄</span> Loading...
            </div>
          ) : (
            data && (
              <MyBox
                overallStatus={data.overallStatus}
                isRunning={data.isInfrastructureUpAndRunning}
                datt={data.triggeredAtUtc}
                selectedEnvironment={selectedEnvironment}
              />
            )
          )}
        </div>
        <div className="dat-text">
          <h3>StreetThings</h3>
          <p>
            StreetThings is a microservices-based application built using
            ASP.NET Core and modern cloud technologies. It integrates a variety
            of tools and frameworks, including gRPC, REST APIs, RabbitMQ,
            Kubernetes, and Docker, to create a scalable and efficient system.
            The application is deployed on Azure Kubernetes Service (AKS), with
            containerized services and several database systems like Azure SQL
            Managed Database and MySQL. This article provides an overview of
            StreetThings architecture, its key backend services, infrastructure
            components, and how they work together to ensure reliable deployment
            and operation.
          </p>
          <h3>
            <TrackableLink href="https://dev.azure.com/StreetThings/_git/Infrastructure">
              Infrastructure:
            </TrackableLink>
          </h3>
          <p>
            The infrastructure for StreetThings is managed through a Terraform
            repository as Infrastructure as Code (IaC), primarily leveraging
            Azure as the cloud provider. StreetThings Infrastructure:
          </p>
          <p>
            {" "}
            The infrastructure for StreetThings is intentionally designed to
            handle a wide array of technologies:
          </p>
          <ul className="list-type1">
            <li> Multiple Azure SQL Managed Databases.</li>
            <li>MySQL installed on a virtual machine.</li>
            <li>
              Separate Azure Container Registries (ACR) for each environment.
            </li>
            <li>
              Azure DevOps Service Connections to ACRs for each back-end service
              repository.
            </li>
            <li>Azure Virtual Network with multiple subnets.</li>
            <li>
              Separate Azure Kubernetes Service (AKS) clusters for each
              environment, with service connections through Azure DevOps.
            </li>
            <li>Azure Log Analytics workspace for monitoring.</li>
            <li>A virtual machine hosting the RabbitMQ message broker.</li>
            <li>Private DNS Zone to support service discovery.</li>
          </ul>
          <p>
            This list is not exhaustive; you can explore the complete
            infrastructure {"  "}
            <TrackableLink href="https://dev.azure.com/StreetThings/_git/Infrastructure">
              here
            </TrackableLink>
            .
          </p>
          <h3>Kubernetes:</h3>
          <p>
            StreetThings runs on AKS (Azure Kubernetes Service) and uses the
            Kubernetes NGINX Ingress Controller as a reverse proxy and load
            balancer. The Ingress Controller syncs the TLS certificates with
            Azure Key Vault using the SecretProviderClass. Periodically, the
            Ingress Controller will issue a new certificate using cert-manager
            when the current certificate near its end of life. The
            SecretProviderClass is also utilized to fetch secrets from Azure Key
            Vault and inject them into Kubernetes pods.
          </p>
          <h3>CI/CD:</h3>
          <p>
            Services, Libraries and infrastructure code each have a CI/CD
            pipelines for each environment (dev, test, and prod). The pipelines
            can be found in each code repository under /.azure
          </p>

          <h3>Back-End Services:</h3>
          <ul className="list-type2">
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Authorization">
                Auth
              </TrackableLink>
            </li>
            Built with ASP.NET 6.0, using IdentityServer4 and connected to Azure
            SQL Managed Database.
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Catalog">
                Catalog
              </TrackableLink>
            </li>
            Developed with ASP.NET Core 6.0, utilizing Entity Framework Core to
            interact with Azure SQL Managed Database. It is containerized with
            Docker and deployed as a pod in the Kubernetes cluster.
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/Transaction">
                Transaction
              </TrackableLink>
            </li>
            This service handles transaction-related functionality.
            <li>
              <TrackableLink href="https://dev.azure.com/StreetThings/_git/AdminGateway">
                Admin Gateway
              </TrackableLink>
            </li>
            A Back-end For Front-end (BFF) service that serves as a gateway and
            aggregator for the Angular front-end. It utilizes Ocelot for basic
            request forwarding and routing and it has its own logic for complex
            requests.
          </ul>
          <h3>Shared Libraries:</h3>
          <p>
            The back-end shares three libraries, which are hosted on an Azure
            DevOps Artifact feed. Here are the source code for{" "}
            <TrackableLink href="https://dev.azure.com/StreetThings/_git/CommonLibrary">
              CommonLibrary
            </TrackableLink>
            ,{" "}
            <TrackableLink href="https://dev.azure.com/StreetThings/_git/EventBus">
              EventBus
            </TrackableLink>
            ,{" "}
            <TrackableLink href="https://dev.azure.com/StreetThings/_git/EventLog.DataAccessLaye">
              EventLog.DataAccessLayer
            </TrackableLink>{" "}
            , and{" "}
            <TrackableLink href="https://dev.azure.com/StreetThings/HostingHelpers">
              {" "}
              HostingHelpers
            </TrackableLink>
          </p>
          <p>
            In conclusion, StreetThings is a meticulously designed
            microservices-based application, built with attention to both
            internal architecture and external service communication. Its use of
            modern design patterns, technologies, and cloud infrastructure
            ensures scalability, security, and performance. StreetThings
            embodies best practices in microservice development and deployment.
          </p>
        </div>
        <div className="div-color">
        <iframe title="draw.io" src="https://viewer.diagrams.net/?tags=%7B%7D&lightbox=1&highlight=0000ff&layers=1&nav=1&title=Kubernetes%20deplyoment.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1rc-kBnKIPdGjP56BZ6BAlk-mZaVCkMMX%26export%3Ddownload"
          style={{
            width:'100%' , height:'100%' ,border: '2px solid black'
          }}
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
